@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  font-family: 'Raleway', sans-serif;
  text-align: center;
  background-color:#fcfced;
  min-height:100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  opacity:0;
  -webkit-animation: fadein 2s forwards;
          animation: fadein 2s forwards;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  font-size: calc(10px + 2vmin);
  color: black;
}

.header-title {
  -webkit-animation: fadein 2s forwards;
          animation: fadein 2s forwards;
  font-size:64px;
}

.Links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top:50px;
}

.Links a{
  padding:10px;
  color: #61dafb;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes fadein {
  0% {
      opacity:0;
  }
  100% {
      opacity:1;
  }
}

@keyframes fadein {
  0% {
      opacity:0;
  }
  100% {
      opacity:1;
  }
}

@-webkit-keyframes grow {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
}

@keyframes grow {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
}
.welcome-content{
  display:flex;
  flex-direction: row;
  justify-content: center;
}

.cards {
  display:flex;
  flex-direction: column;
  justify-content: center;
}

.card {
  /* Add shadows to create the "card" effect */
  height:100px;
  width:100px;
  background-color:white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  margin:25px;
  display:flex;
  justify-content: center;
  align-items: center;
}

.card:hover{
  -webkit-animation: grow 1s forwards;
          animation: grow 1s forwards;
}

.card-img {
  max-height:50%;
  max-width:50%;
}

@media only screen and (max-width: 600px) {
  .welcome-content{
    display:flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .cards {
    display:flex;
    flex-direction: row;
    justify-content: center;
  }
  
}
.about-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

