@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap');

.App {
  font-family: 'Raleway', sans-serif;
  text-align: center;
  background-color:#fcfced;
  min-height:100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  opacity:0;
  animation: fadein 2s forwards;
  animation-delay: 2s;
  font-size: calc(10px + 2vmin);
  color: black;
}

.header-title {
  animation: fadein 2s forwards;
  font-size:64px;
}

.Links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top:50px;
}

.Links a{
  padding:10px;
  color: #61dafb;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadein {
  0% {
      opacity:0;
  }
  100% {
      opacity:1;
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}
.welcome-content{
  display:flex;
  flex-direction: row;
  justify-content: center;
}

.cards {
  display:flex;
  flex-direction: column;
  justify-content: center;
}

.card {
  /* Add shadows to create the "card" effect */
  height:100px;
  width:100px;
  background-color:white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  margin:25px;
  display:flex;
  justify-content: center;
  align-items: center;
}

.card:hover{
  animation: grow 1s forwards;
}

.card-img {
  max-height:50%;
  max-width:50%;
}

@media only screen and (max-width: 600px) {
  .welcome-content{
    display:flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .cards {
    display:flex;
    flex-direction: row;
    justify-content: center;
  }
  
}